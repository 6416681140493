import React from 'react';

import Layout from '../../../../components/MenuLayout';
import { PatientManagment } from '../../../../components/Hospital/PatientManagement/PatientManagement';

export default () => (
  <Layout type="settings">
    <PatientManagment />
  </Layout>
);
